import React, { FC } from 'react';

export const Header: FC = () => (
  <div className={'item-container'}>
    <div className={'image-container'}>
      <h1 className={'title-header'}>Wij gaan trouwen!</h1>
      <br />
      <h1 className={'title-names-header'}>Gina</h1>
      <h1 className={'title-header'}>&</h1>
      <h1 className={'title-names-header'}>Quinten</h1>
      <br />
      <h1 className={'title-header'}>15 mei 2025</h1>
    </div>
  </div>
);

import React, { FC } from 'react';

import { Label } from '../database/invites';

export const InviteText: FC<{
  name: string;
  partner?: string | null;
  isNight: boolean;
  labels?: Label[];
}> = ({ name, partner, isNight, labels }) => {
  return (
    <div className="item-container">
      <p className={'card card-content'} data-isnight={isNight}>
        Lieve {name}
        {partner ? ` & ${partner},` : ','}
        <br />
        <br />
        {renderText(!!partner, isNight, labels)}
      </p>
    </div>
  );
};

const renderText = (partner: boolean, isNight: boolean, labels?: Label[]) => {
  if (labels?.includes(Label.Feest) && labels?.includes(Label.Receptie)) {
    return renderReceptionText(partner);
  }

  return isNight ? renderNightText(partner) : renderDayText(partner);
};

const renderNightText = (partner: boolean) => (
  <>
    Graag nodigen wij {partner ? 'jullie' : 'jou'} uit om deze bijzondere avond
    samen met ons te vieren en te proosten op de liefde.
    {renderFooter(true)}
    <br />
    Zou je willen laten weten, via onderstaande RSVP, of{' '}
    {partner ? 'jullie' : 'je'} er deze avond bij {partner ? 'kunnen' : 'kunt'}{' '}
    zijn.
    <br />
    <br />
    Wij hopen {partner ? 'jullie' : 'je'} dan te zien!
    <br />
    <br />
    {renderCadeauTip()}
    {renderMeesters()}
  </>
);

const renderDayText = (partner: boolean) => (
  <>
    Wij kunnen niet wachten om elkaar het jawoord te geven en vinden het fijn
    als {partner ? 'jullie' : 'jij'} erbij {partner ? 'zijn' : 'bent'} op deze
    speciale dag. <br /> <br />
    Graag nodigen we {partner ? 'jullie' : 'jou'} uit op 15 mei 2025 voor de
    ceremonie en het feest. {partner ? 'Zouden jullie' : 'Zou je'} willen laten
    weten, via de onderstaande RSVP, of {partner ? 'jullie' : 'je'} er deze dag
    bij {partner ? 'kunnen' : 'kunt'} zijn om met ons de liefde te vieren?
    {renderFooter(false)}
  </>
);

const renderReceptionText = (partner: boolean) => (
  <>
    Graag nodigen wij {partner ? 'jullie' : 'jou'} uit om deze bijzondere dag
    samen met ons te vieren en te proosten op de liefde.
    <br />
    <br />
    De toost en taart vindt plaats bij Het Oude Raadhuis.
    <br />
    <b>Adres:</b> Dorpsstraat 65, Castricum <br />
    <b>Tijd:</b> 16:00 - 17:00 <br />
    <br />
    Het feest is bij Strandpaviljoen Zeezicht.
    <br />
    <b>Adres:</b> Zeeweg 50, Castricum <br />
    <b>Tijd:</b> 20:30 - 01:00 <br />
    <br />
    Zou je willen laten weten, via onderstaande RSVP, bij welke onderdelen{' '}
    {partner ? 'jullie' : 'je'} aanwezig {partner ? 'kunnen' : 'kan'} zijn. Dit
    graag vermelden als opmerking.
    <br />
    <br />
    {renderCadeauTip()}
    {renderMeesters()}
  </>
);

const renderFooter = (isNight: boolean) => (
  <>
    <br />
    <br />
    <b>Locatie:</b> {isNight ? 'Strandpaviljoen Zeezicht' : 'Castricum'} <br />
    {isNight && (
      <>
        <b>Adres:</b> Zeeweg 50, Castricum <br />
      </>
    )}
    <b>Tijd:</b> {isNight ? '20:30 - 01:00 ' : '15:00'}
    <br />
    {!isNight && (
      <>
        <br />
        Verdere informatie volgt in de officiële uitnodiging.
        <br />
      </>
    )}
    {!isNight && renderMeesters()}
  </>
);

const renderMeesters = () => (
  <>
    <br /> <b>Ceremoniemeesters</b>
    <br /> Sterre (0611095013) & Milou (0658939739)
  </>
);

const renderCadeauTip = () => (
  <>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <i>Cadeautip</i>

      <svg
        width="30px"
        height="24px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_15_17)">
          <path
            d="M3 17.5V6.5C3 5.94772 3.44772 5.5 4 5.5H12H20C20.5523 5.5 21 5.94772 21 6.5V17.5C21 18.0523 20.5523 18.5 20 18.5H4C3.44772 18.5 3 18.0523 3 17.5Z"
            stroke="#b5756f"
            stroke-linejoin="round"
          />
          <path d="M3 6L12 12L21 6" stroke="#b5756f" stroke-linejoin="round" />
        </g>
      </svg>
    </div>
  </>
);

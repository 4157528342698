import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from './firebase';
import { DocumentData, DocumentReference } from '@firebase/firestore';

export enum Rsvp {
  Yes = 'yes',
  No = 'no',
  Maybe = 'maybe',
}

export enum Label {
  Feest = 'Feest',
  Diner = 'Diner',
  Ceremonie = 'Ceremonie',
  Receptie = 'Receptie',
}

export type Invite = {
  name: string;
  partner: string | null;
  isNight: boolean;
  labels?: Label[];
  email?: string;
  address?: string;
  remark?: string;
  rsvp?: Rsvp;
  partnerRsvp?: Rsvp;
};

type UpdateInvite = {
  email: string;
  address: string;
  rsvp: Rsvp;
  partnerRsvp?: Rsvp;
  remark?: string;
};

const COLLECTION_NAME = 'invites';

const getReference = (
  id: string,
): DocumentReference<DocumentData, DocumentData> =>
  doc(db, COLLECTION_NAME, id);

export const getInviteByID = async (id: string): Promise<Invite> => {
  const snapshot = await getDoc(getReference(id));
  return snapshot.data() as Invite;
};

export const updateInviteByID = async (
  id: string,
  invite: UpdateInvite,
): Promise<void> => {
  await updateDoc(getReference(id), invite);
};
